'use client';
import {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
  use,
} from 'react';
import { useAuth } from '../AuthContext';
import { toast } from 'react-hot-toast';
import {
  fetchData,
  addToCartWish,
  deleteFromCartWish,
  editCartQuantity,
} from './api';
import { useRouter } from 'next/navigation';
import getToken from '@/hook/getToken';
import BASE_URL from '@/components/constants';


export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const { isSignInOpen, setIsSignInOpen, setIsSwap } = useAuth();
  const router = useRouter();
  const [cart, setCart] = useState();
  const [buyItems, setBuyItems] = useState();
  const [isBuyNow, setIsBuyNow] = useState(false);
  const [couponId, setCouponId] = useState();
  const [isMutating, setIsMutating] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('online');
  const [orderResData, setOrderResData] = useState();
  const [appliedCoupon, setAppliedCoupon] = useState('');
  const [apiUrl, setApiUrl] = useState('')
  const [buyNowUrl, setBuyNowUrl] = useState('')
  const [isWalletSelected, setIsWalletSelected] = useState(false)

  const [isPending, setIsPending] = useState(false)

  // //razorpay state
  // const [razorpay, setRazorpay] = useState(null)
  useEffect(() => {
    const couponId = sessionStorage.getItem('appliedCouponId')
    const couponName = sessionStorage.getItem('couponName')
    if (couponId && couponName) {
      setAppliedCoupon(couponName)
      setCouponId(couponId)
    }
  }, [])

  // apiurl for fetching cart data
  useEffect(() => {
    let apiUrl = '/v1/app/get/cart?page=1&limit=500&payment_method=online';
    if (couponId) {
      apiUrl = apiUrl + `&coupon_id=${couponId}`
    }
    if (paymentMethod != 'online') {
      apiUrl = apiUrl.replace('&payment_method=online', '');
    }
    if (isWalletSelected) {
      apiUrl += `&select_wallet=true`
    }
    setApiUrl(apiUrl)
  }, [couponId, paymentMethod, isWalletSelected])

  // api url for buy now data with login
  useEffect(() => {

    let apiUrl = '/v1/app/get/cart?page=1&limit=500&buy_now=true&payment_method=online';
    if (couponId) {
      apiUrl = apiUrl + `&coupon_id=${couponId}`
    }
    if (paymentMethod != 'online') {
      apiUrl = apiUrl.replace('&payment_method=online', '');
    }
    if (isWalletSelected) {
      apiUrl += `&select_wallet=true`
    }
    setBuyNowUrl(apiUrl)
  }, [couponId, paymentMethod, isWalletSelected])


  useEffect(() => {
    const token = getToken();
    if (!token) return;

    fetchCartItems(apiUrl);
  }, [isSignInOpen, apiUrl]);
  useEffect(() => {
    const data = localStorage.getItem('orderData');
    if (!data) return;
    setOrderResData(JSON.parse(data));
  }, []);
  const fetchCartItems = useCallback(
    async (apiUrl) => {
      const token = getToken();
      if (!token) return;
      setIsMutating(true)
      try {
        const couponid = couponId;
        if (!apiUrl || apiUrl == '/') return
        const response = await fetchData(apiUrl);
        const data = await response.json();

        if (response.ok) {
          setCart(data);
        }
      } catch (error) {
        console.log('Error fetching cart items:', error);
      } finally {
        setIsMutating(false)
      }
    },
    [couponId]
  );

  const addToCart = async (productId, orderQty) => {
    const body = {
      product_id: productId,
      order_qty: orderQty,
    };
    try {
      const response = await addToCartWish('/v1/cart/add', body);
      const data = await response.json();
      if (response.ok) {
        fetchCartItems(apiUrl);
        toast.success(data.message);
      }
      if (data.message === 'Unauthorized') {
        setIsSignInOpen(true);
      }
      return data;
    } catch (error) { }
  };

  const removeFromCart = async (cartId, action) => {
    const url = `/v1/cart/remove/${cartId}?wishlist=${action}`;
    try {
      const response = await deleteFromCartWish(url);
      if (response.message === 'Unauthorized') {
        setIsSignInOpen(true);
      }
      if (response.ok) {
        fetchCartItems(apiUrl);
        setIsSwap(pre => !pre)
        fetchBuyNow();
        toast.success(`Succeccfully remove from your cart.`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.log('An error occurred:', error);
    }
  };

  const changeQty = async (id, qty) => {
    setIsMutating(true);
    const url = `/v1/cart/edit/${id}`;
    const body = {
      order_qty: qty,
    };

    try {
      const response = await editCartQuantity(url, body);
      if (response.message === 'Unauthorized') {
        setIsSignInOpen(true);
      }
      const resData = await response.json();
      if (response.ok) {
        fetchCartItems(apiUrl);
        fetchBuyNow();
        setIsMutating(false);
        toast.success(resData.message);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      setIsMutating(false);
    }
  };

  const initializeRazorpay = () => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/razorpay.js';

      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };

      document.body.appendChild(script);
    });
  };

  const initPayment = async (data, id, paymentMethod, buyNow, prefill, methodData, appOpendata) => {
    const res = await initializeRazorpay();

    if (!res) {
      alert('Razorpay SDK Failed to load');
      return;
    }

    const razorpay = new window.Razorpay({
      key: process.env.NEXT_PUBLIC_RAZORPAY_KEY,
    });

    // if (methodData.method === 'upi') {
    //   razorpay.verifyVpa(methodData.upi.vpa)
    //     .then((d) => {
    //       console.log('validation upi ', d);
    //       // VPA is valid, ask the user to click Pay
    //     })
    //     .catch(() => {
    //       // VPA is invalid, show an error to the user
    //     });
    // }

    const options = {
      amount: data.amount,
      currency: data.currency,
      description: '21GenX',
      order_id: data.id,
      contact: prefill.contact,
      email: prefill.email,
      ...methodData
    };

    if (appOpendata) {
      razorpay.createPayment(options, appOpendata)
    } else {
      razorpay.createPayment(options)
    }
    razorpay.on('payment.error', function (resp) {
      setIsPending(false)
      toast.error(resp.error.description)
    });
    razorpay.on('payment.success', function (resp) {
      setIsPending(false)
      verifyPayment(id, buyNow, resp)
    });
  };

  //data for buy now option

  const fetchBuyNow = async (url) => {
    setIsMutating(true)
    const apiUrl = url ? url : buyNowUrl
    if (!apiUrl || apiUrl == '/') return
    const response = await fetchData(apiUrl);
    const data = await response.json();

    if (response.ok) {
      try {
        setBuyItems(data);
      } catch (error) {
        console.log('An error occurred:', error);
      } finally {
        setIsMutating(false)
      }
    }
  };
  useEffect(() => {
    const token = getToken();
    const sessionId = localStorage.getItem('sessionId');
    if (!token) return;
    if (sessionId) return;
    fetchBuyNow(buyNowUrl);
  }, [buyNowUrl]);

  //quick buy withour login
  const [isQuickBuy, setIsQuickBuy] = useState(false);
  useEffect(() => {
    const sessionId = localStorage.getItem('sessionId');
    if (!sessionId) return;
    if (!paymentMethod) return;
    let apiUrl = `${BASE_URL}/v1/app/get-quick-checkout?session_id=${sessionId}&payment_method=${paymentMethod}`
    if (couponId) {
      apiUrl += `&coupon_id=${couponId}`
    }
    const fetchQuickBuyItems = async () => {
      setIsMutating(true)
      try {
        const response = await fetch(apiUrl);
        const data = await response.json();
        if (response.ok) {
          setBuyItems(data);
        }
      } catch (error) {
        console.error('Failed to fetch quick buy items', error);
      } finally {
        setIsMutating(false)
      }
    };

    fetchQuickBuyItems();
  }, [isQuickBuy, paymentMethod, couponId]);

  const handleOrderPlace = async (id, paymentMethod, buyNow, token, methodData, appOpendata) => {
    setIsPending(true)
    try {
      let apiUrl = BASE_URL + '/v1/orders/place-order';

      const orderData = {
        shipping_address_id: id,
        billing_address_id: id,
        buy_now: buyNow,
      };

      // Conditionally add couponId to orderData if it's not undefined
      if (couponId !== undefined) {
        orderData.coupon_id = couponId;
      }

      // Conditionally set the API endpoint based on paymentMethod
      if (paymentMethod === 'cash') {
        apiUrl = BASE_URL + '/v1/orders/place-order';
        orderData.payment_method = paymentMethod;
        if (isWalletSelected) {
          orderData.select_wallet = true;
        }
      } else if (paymentMethod == 'genx_wallet') {
        orderData.payment_method = paymentMethod;
        orderData.select_wallet = true;
      } else if (paymentMethod === 'online') {
        apiUrl = BASE_URL + '/v1/orders/payment-order-b2c';
        if (isWalletSelected) {
          orderData.select_wallet = true;
        }
        // Don't include paymentMethod for 'online'
      }

      // Set up the authorization header with the token
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(orderData),
      };

      const response = await fetch(apiUrl, requestOptions);
      const responseData = await response.json();
      console.log('responseData', responseData);
      if (response.ok) {
        if (paymentMethod === 'online') {
          initPayment(
            responseData.data,
            id,
            paymentMethod,
            buyNow,
            responseData.prefill,
            methodData,
            appOpendata
          );
        }
        setOrderResData(responseData?.order_details);
        if (responseData?.order_details) {
          localStorage.setItem(
            'orderData',
            JSON.stringify(responseData?.order_details)
          );
        }
        if (paymentMethod == 'cash') {
          setIsMutating(false)
          fetchCartItems(apiUrl);
          toast.success(
            'Congratulations! Your order has been placed successfully.'
          );
          router.push('/success');
        }
        if (paymentMethod == 'genx_wallet') {
          setIsMutating(false)
          fetchCartItems(apiUrl);
          toast.success(
            'Congratulations! Your order has been placed successfully.'
          );
          router.push('/success');
        }
      } else {
        setIsPending(false)
        // Handle the error when the order placement fails
        toast.error(responseData.message);
        console.error('Failed to place the order:', responseData);
      }
    } catch (error) {
      console.error('An error occurred:', error.message);
    } finally {
      localStorage.removeItem('sessionId');
    }
  };
  // verify  payment
  const verifyPayment = async (id, buyNow, resp) => {
    try {
      const verifyUrl = BASE_URL + '/v1/orders/payment-verify-b2c';
      const requestBody = {
        billing_address_id: id,
        buy_now: buyNow,
        shipping_address_id: id,
      };
      if (isWalletSelected) {
        requestBody.select_wallet = true
      }
      // Conditionally add couponId to orderData if it's not undefined
      if (couponId !== undefined) {
        requestBody.coupon_id = couponId;
      }
      const token = getToken();
      delete resp.status_code;
      const combinedData = {
        ...resp,
        ...requestBody,
      };

      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(combinedData),
      };

      const response = await fetch(verifyUrl, requestOptions);
      const { order_details } = await response.json();
      setOrderResData(order_details);
      if (order_details) {
        localStorage.setItem('orderData', JSON.stringify(order_details));
      }
      if (response.ok) {
        fetchCartItems('/v1/app/get/cart?page=1&limit=500');
        router.push('/success');
      }
    } catch (error) {
      console.log(error);
    }
  }
  // deleting quick buy item
  const deleteQuickbuyItem = async (productId, sessionId) => {
    const url = `${BASE_URL}/v1/cart/remove-quick-buy?session_id=${sessionId}`;
    try {
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        toast.success(`Succeccfully remove from your cart.`);
        setBuyItems();
      }
    } catch (error) {
      console.log('An error occurred:', error);
    }
  };
  // quantity change option
  const quickBuyQuantityChange = async (sessionId, qty) => {
    setIsMutating(true);
    const url = `${BASE_URL}/v1/cart/edit-quick-buy-qty?session_id=${sessionId}`;
    const body = {
      order_qty: qty,
    };

    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      if (response.ok) {
        toast.success('Quantity updated successfully.');
        setIsQuickBuy((pre) => !pre);
      }
    } catch (error) {
      console.log('An error occurred:', error);
    } finally {
      setIsMutating(false);
    }
  };

  // Memoize the context value using useMemo
  const memoizedValue = useMemo(() => {
    return {
      cart,
      setCart,
      setBuyItems,
      addToCart,
      changeQty,
      removeFromCart,
      handleOrderPlace,
      isMutating,
      couponId,
      setCouponId,
      isBuyNow,
      setIsBuyNow,
      buyItems,
      orderResData,
      deleteQuickbuyItem,
      quickBuyQuantityChange,
      paymentMethod,
      setPaymentMethod,
      appliedCoupon,
      setAppliedCoupon,
      isWalletSelected,
      setIsWalletSelected,
      isPending
    };
  }, [
    cart,
    setCart,
    setBuyItems,
    isMutating,
    addToCart,
    changeQty,
    removeFromCart,
    handleOrderPlace,
    couponId,
    setCouponId,
    isBuyNow,
    setIsBuyNow,
    buyItems,
    setBuyItems,
    orderResData,
    deleteQuickbuyItem,
    quickBuyQuantityChange,
    paymentMethod,
    setPaymentMethod,
    appliedCoupon,
    setAppliedCoupon,
    isWalletSelected,
    setIsWalletSelected,
    isPending
  ]);

  return (
    <CartContext.Provider value={memoizedValue}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  return useContext(CartContext);
};
